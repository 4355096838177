import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    UntypedFormGroup,
} from '@angular/forms';
import { BaseFormField } from 'src/app/shared/flex-forms/base-form-field.class';
import { FlexFormField } from 'src/app/shared/flex-forms/flex-form-field.class';

@Component({
    selector: 'reactive-checkbox',
    templateUrl: './reactive-checkbox.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ReactiveCheckboxComponent)
        }
    ]
})
export class ReactiveCheckboxComponent extends BaseFormField implements ControlValueAccessor {
    @Input() field: FlexFormField;
    @Input() form: UntypedFormGroup;

    @Output() isFieldHidden = new EventEmitter<boolean>(false);

    isAgreed = false;

    constructor() {
        super();
    }

    writeValue(newIsAgreed: boolean) {
        this.isAgreed = newIsAgreed;
    }

    onChange($event) {
        this.isAgreed = $event;
        this.propagateChange(
            this.isAgreed
        );
    }

    onTouch() {
        this.propagateTouch();
    }
}
