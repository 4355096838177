import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SponsorValidationService {
    constructor(private http: HttpClient) { }

    validateEmailAddress(enrollmentGroupExternalId: string, emailAddress: string): Promise<Boolean> {
      const emailValidationUrl = `/enrollment-api/enrollment-group/${enrollmentGroupExternalId}/email/validate`;

      return this.http.post(emailValidationUrl, { emailAddress }).toPromise().then(() => {
        return true;
      }).catch(() => {
        return false;
      });
    }
}
