import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormField } from 'src/app/shared/flex-forms/base-form-field.class';
import { FlexFormField } from 'src/app/shared/flex-forms/flex-form-field.class';
import { FlexFormDropdownData } from 'src/app/shared/flex-forms/flex-form-dropdown-data.class';
import { DropdownOptions } from 'src/app/shared/dropdown-options.model';

@Component({
    selector: 'reactive-state-province',
    templateUrl: './reactive-state-province.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ReactiveStateProvinceComponent)
        }
    ]
})
export class ReactiveStateProvinceComponent extends BaseFormField implements OnInit, ControlValueAccessor {

    @Input() field: FlexFormField;
    @Input() enrollmentGroupExternalId: string;

    stateProvinces: string[] = [];
    selectedStateProvince: string = '';
    grooveOptions: DropdownOptions[] = [];

    ngOnInit() {
        this.stateProvinces = (this.field?.values as FlexFormDropdownData[])
            .map((dropdownData) => dropdownData.name);

        this.grooveOptions = this.stateProvinces?.map((stateProvince) => {
           return { text: stateProvince }
        });

        if (this.field?.initialValue) {
            this.selectedStateProvince = this.field.initialValue;
        }
    }

    onChange(name: string) {
        this.selectedStateProvince = name;
        this.propagateChange(this.selectedStateProvince);
    }

    onTouch() {
        this.propagateTouch();
    }

    writeValue(newStateProvince: string) {
        this.selectedStateProvince = newStateProvince;
    }
}
