<div class="checkbox-component">
    <groove-checkbox-field class="groove-wrapper"
                           [id]="field.fieldName + '-groove-field'"
                           [fieldName]="field.fieldName"
                           (valueChanged)="onChange($event)"
                           (touched)="onTouch()"
                           [value]="false"
                           [isDisabled]="disabled"
                           [isRequired]="field.required"
                           [content]="field.displayName">
    </groove-checkbox-field>
</div>
