<div>
    <div>
        <groove-password-field class="groove-wrapper"
                               [id]="field.fieldName + '-input'"
                               [fieldName]="field.fieldName"
                               [label]="field.displayName"
                               [isDisabled]="field.editable === false"
                               (valueChanged)="onPasswordChange($event)"
                               (touched)="onPasswordTouch()"
                               [placeholder]="field.placeholder"
                               [additionalAssistiveText]="'enrollment.forms.passwordHelp.passwordRequirements' | translate"
                               [isRequired]="isRequired">
        </groove-password-field>

        <ul class="password-errors"
            [id]="field.fieldName + '-errors'"
            [attr.aria-label]="'enrollment.flex.passwordRequirements' | translate"
            aria-live="polite">
            <li class="list-group-item password-requirement">
                <img aria-hidden="true"
                     class="requirement-status-icon"
                     [id]="field.fieldName + '-uppercase-error'"
                     [src]="this.getPasswordRequirementStatusIcon(fieldErrors[VALIDATORS.UPPERCASE])">
                <span aria-hidden="true" [translate]="'enrollment.forms.passwordHelp.oneUpperCase'"></span>
                <span class="sr-only"
                      [translate]="'enrollment.flex.requirementMet' | translate: { requirement: 'enrollment.forms.passwordHelp.oneUpperCase' | translate }"
                      *ngIf="password && !fieldErrors[VALIDATORS.UPPERCASE]">
                </span>
            </li>

            <li class="list-group-item password-requirement">
                <img aria-hidden="true"
                     class="requirement-status-icon"
                     [id]="field.fieldName + '-lowercase-error'"
                     [src]="this.getPasswordRequirementStatusIcon(fieldErrors[VALIDATORS.LOWERCASE])">
                <span aria-hidden="true" [translate]="'enrollment.forms.passwordHelp.oneLowerCase'"></span>
                <span class="sr-only"
                      [translate]="'enrollment.flex.requirementMet' | translate: { requirement: 'enrollment.forms.passwordHelp.oneLowerCase' | translate }"
                      *ngIf="password && !fieldErrors[VALIDATORS.LOWERCASE]">
                </span>
            </li>

            <li class="list-group-item password-requirement">
                <img aria-hidden="true"
                     class="requirement-status-icon"
                     [id]="field.fieldName + '-number-error'"
                     [src]="this.getPasswordRequirementStatusIcon(fieldErrors[VALIDATORS.NUMBER])">
                <span aria-hidden="true" [translate]="'enrollment.forms.passwordHelp.oneNumber'"></span>
                <span class="sr-only"
                      [translate]="'enrollment.flex.requirementMet' | translate: { requirement: 'enrollment.forms.passwordHelp.oneNumber' | translate }"
                      *ngIf="password && !fieldErrors[VALIDATORS.NUMBER]">
                </span>
            </li>

            <li class="list-group-item password-requirement">
                <img aria-hidden="true"
                     class="requirement-status-icon"
                     [id]="field.fieldName + '-symbol-error'"
                     [src]="this.getPasswordRequirementStatusIcon(fieldErrors[VALIDATORS.SYMBOL])">
                <span aria-hidden="true" [translate]="'enrollment.forms.passwordHelp.oneSpecialCharacter'"></span>
                <span class="sr-only"
                      [translate]="'enrollment.flex.requirementMet' | translate: { requirement: 'enrollment.forms.passwordHelp.oneSpecialCharacter' | translate }"
                      *ngIf="password && !fieldErrors[VALIDATORS.SYMBOL]">
                </span>
            </li>

            <li class="list-group-item password-requirement">
                <img aria-hidden="true"
                     class="requirement-status-icon"
                     [id]="field.fieldName + '-length-error'"
                     [src]="this.getPasswordRequirementStatusIcon(fieldErrors[VALIDATORS.PASSWORD_LENGTH])">
                <span aria-hidden="true" [translate]="'enrollment.forms.passwordHelp.lengthSpecifications'"></span>
                <span class="sr-only"
                      [translate]="'enrollment.flex.requirementMet' | translate: { requirement: 'enrollment.forms.passwordHelp.lengthSpecifications' | translate }"
                      *ngIf="password && !fieldErrors[VALIDATORS.PASSWORD_LENGTH]">
                </span>
            </li>
        </ul>

        <span [id]="field.fieldName + '-screenreader-requirement-not-met-alert'"
              class="sr-only"
              role="alert"
              *ngIf="password && getScreenReaderRequirementsNotMetMessage()"
              [attr.aria-label]="getScreenReaderRequirementsNotMetMessage()">
        </span>
        <div aria-live="polite">
            <span [id]="field.fieldName + '-screenreader-requirement-met-alert'"
                  class="sr-only"
                  [translate]="'enrollment.flex.requirementMet' | translate: { requirement: '' }"
                  *ngIf="password && !getScreenReaderRequirementsNotMetMessage()">
            </span>
        </div>
    </div>

    <div>
        <groove-password-field class="groove-wrapper"
                               [id]="field.fieldName + '-confirmation-input'"
                               [fieldName]="field.fieldName + 'Confirmation'"
                               [label]="'enrollment.flex.passwordConfirmation' | translate"
                               [isDisabled]="field.editable === false"
                               (valueChanged)="onConfirmationChange($event)"
                               (touched)="onConfirmationTouch()"
                               [placeholder]="'enrollment.flex.reenterYourPassword' | translate"
                               [isRequired]="isRequired">
        </groove-password-field>

        <div aria-live="polite">
            <span [translate]="'enrollment.forms.errors.passwordsNotMatch'"
                  role="alert"
                  class="error-text"
                  [id]="field.fieldName + '-confirmation-error'"
                  *ngIf="password && passwordConfirmation && fieldErrors[VALIDATORS.CONFIRMATION] && passwordConfirmationTouched">
            </span>

            <span class="sr-only"
                  [id]="field.fieldName + '-screenreader-confirmation-message'"
                  [translate]="'enrollment.flex.requirementMet' | translate: { requirement: '' }"
                  *ngIf="password && passwordConfirmation && !fieldErrors[VALIDATORS.CONFIRMATION]">
            </span>
        </div>
    </div>
</div>
