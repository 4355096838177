<div #appContentArea class="app-container" [ngStyle]="{ 'background-image': 'url(' + coverPhoto + ')' }">
    <ie-warning-banner></ie-warning-banner>
    <div class="app-content container-fluid">
        <div class="app-content-inner row">
            <div class="app-content-container-wrapper">
                <div class="app-content-container">
                    <ui-view></ui-view>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-container">
        <footer [sponsorSettings]="sponsorSettings"></footer>
    </div>
</div>
