import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GenericGrooveWrapperComponent } from '../generic-groove-wrapper/generic-groove-wrapper.component';

@Component({
    selector: 'groove-checkbox-field',
    templateUrl: './groove-checkbox-field.component.html',
    styleUrls: ['./groove-checkbox-field.component.scss']
})
export class GrooveCheckboxFieldComponent extends GenericGrooveWrapperComponent {
    @Input()
    hasLink = false;

    @Input()
    linkText = '';

    @Output()
    clickedOnLink: EventEmitter<string> = new EventEmitter<string>();

    @Input()
    isMedium = false;

    @Input()
    content = '';

    beforeTitle: string;
    afterTitle: string;

    ngOnInit() {
        super.ngOnInit();
        if (this.linkText) {
            this.splitTranslation();
        }
    }

    splitTranslation() {
        const titleIndex = this.label.indexOf(this.linkText);

        if (titleIndex !== -1) {
            this.beforeTitle = this.label.substring(0, titleIndex);
            this.afterTitle = this.label.substring(titleIndex + this.linkText.length);
            return;
        }
        this.beforeTitle = '';
        this.afterTitle = '';
    }

    onLinkClick($event) {
        this.clickedOnLink.emit($event);
    }
}
