import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { PhoneNumberCountryItem } from '@engineering/genesis-frontend';
import { Constants } from 'src/app/shared/constants.class';
import { Country } from 'src/app/shared/country.class';
import { CountryService } from 'src/app/shared/country.service';
import { BaseFormField } from 'src/app/shared/flex-forms/base-form-field.class';
import { FlexFormField } from 'src/app/shared/flex-forms/flex-form-field.class';
import { FieldAttributes, PhoneNumberAttributes } from 'src/app/shared/flex-forms/field-attributes.interface';

@Component({
    selector: 'reactive-phone-number-groove',
    templateUrl: './reactive-phone-number.component.html',
    styleUrls: ['./reactive-phone-number.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ReactivePhoneNumberComponent),
            multi: true,
        }
    ]
})
export class ReactivePhoneNumberComponent extends BaseFormField implements ControlValueAccessor, OnInit {

    _field: FlexFormField;
    countryOptions: Array<PhoneNumberCountryItem> = [];
    selectedCountryCode: string = 'US';
    phoneNumber: string = '';

    fieldAttributes: FieldAttributes = { type: 'NoFieldAttributes' };
    checkboxValue: Boolean = false;

    errors: ValidationErrors = {};

    @Input() form: UntypedFormGroup;

    @Input()
    get field(): FlexFormField {
        return this._field;
    }

    set field(newField: FlexFormField) {
        this._field = newField;
        this.phoneNumber = newField?.initialValue;
        this.fieldAttributes = newField.fieldAttributes as FieldAttributes;

        this.countryOptions = (newField.values as Country[])?.filter((country) => {
            return country?.phoneCountryCode != null
        })?.map((country) => {
            return {
                countryCode: country.countryCode,
                phoneCountryCode: country.phoneCountryCode,
                name: country.name
            };
        }) ?? [];
    }

    get touched(): boolean {
        return this.form?.controls[this.field?.fieldName]?.touched || false;
    }

    get VALIDATORS() {
        return Constants.VALIDATORS;
    }

    constructor(private countryService: CountryService) {
        super();
    }

    ngOnInit() {
        this.countryService.getSelectedCountry().subscribe((country) => {
            if (country?.countryCode && country?.phoneCountryCode) {
                this.selectedCountryCode = country?.countryCode;
            }
        });
    }

    onChange(newValue: string) {
        this.phoneNumber = newValue;
        this.propagateChange(newValue);
        this.form?.controls[this.field?.fieldName].setErrors(this.errors);
    }

    onCheckBoxChange(event: any) {
        this.checkboxValue = event.target.checked;
        if (!this.checkboxValue && !this.form?.controls[this.field?.fieldName].errors) {
            this.setErrorsOnForm({agreementCheckbox: true});
        } else if (this.checkboxValue && this.form?.controls[this.field?.fieldName].errors.agreementCheckbox) {
            let { agreementCheckbox, ...remainingErrors} = this.form?.controls[this.field?.fieldName].errors;
            let remainingErrorsNullable = Object.keys(remainingErrors).length ? remainingErrors : null;
            this.setErrorsOnForm(remainingErrorsNullable);
        }
    }

    onError(errors: ValidationErrors) {
        if (errors !== null && !errors.size) {
            let newError = {};
            newError[Constants.VALIDATORS.VALID_PHONE_NUMBER] = true;
            this.setErrorsOnForm(newError);
        } else if (this.fieldAttributes?.type == Constants.FIELD_ATTRIBUTES_TYPE.PHONE_NUMBER && this.checkboxValue == false) {
            this.setErrorsOnForm({agreementCheckbox: true});
        } else {
            this.setErrorsOnForm(null);
        }
        this.errors = this.form?.controls[this.field?.fieldName].errors;
    }

    private setErrorsOnForm(errors: ValidationErrors) {
        this.form?.controls[this.field?.fieldName].setErrors(errors);
    }

    getPhoneNumberAttributesCheckBoxText(): string {
        return (this.fieldAttributes as PhoneNumberAttributes).checkBoxText
    }

    writeValue(newPhoneNumber: string) {
        this.phoneNumber = newPhoneNumber;
    }

    onTouch(): void {
        this.propagateTouch();
    }
}
