import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { GenesisSponsor } from './genesis-sponsor.class'
import { environment } from '../../environments/environment';

@Injectable()
export class GenesisSponsorService {
    constructor(
        private http: HttpClient,
    ) {
    }

    getSponsorsPage(name, paging, isCrossRegion = false) {
        let SPONSORS_URL: string = '/enrollment-api/sponsor-search/sponsors';
        let PLATFORM: string = 'Web';
        paging.page = paging.page || 0;
        paging.pageSize = paging.pageSize || 25;

        let fromObject: { [index: string]: string | string[] };
        if (isCrossRegion) {
            fromObject = {
                name: name,
                platform: PLATFORM,
                page: paging.page,
                pageSize: paging.pageSize
            };
        } else {
            fromObject = {
                name: name,
                region: environment.enrollmentRegion,
                platform: PLATFORM,
                page: paging.page,
                pageSize: paging.pageSize
            };
        }

        const params = new HttpParams({
            fromObject
        });

        return this.http.get<GenesisSponsor[]>(SPONSORS_URL, {
            observe: 'response',
            params
        });

    }
}
