<form class="groove-checkbox-form" [formGroup]="form">
    <vpg-checkbox [ngClass]="{'is-medium': isMedium}" class="groove-checkbox-vpg-checkbox"
                  [formControlName]="fieldName"
                  [name]="fieldName">
        <ng-container *ngIf="linkText">
            <span *ngIf="beforeTitle">{{ beforeTitle }}</span>
            <button class="v-checkbox-link-text" *ngIf="linkText" (click)="onLinkClick($event)">{{ linkText }}</button>
            <span *ngIf="afterTitle">{{ afterTitle }}</span>
        </ng-container>
        <ng-container *ngIf="content">
            <span [id]="fieldName + '-content'">{{ content }}</span>
        </ng-container>
    </vpg-checkbox>
</form>
