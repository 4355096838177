import { Injectable } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn } from "@angular/forms";
import { FlexFormField } from "./flex-form-field.class";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AnalyticsData } from './analytics-data.class';
import { AnalyticsTrackingService } from '../../analytics-tracking/analytics-tracking.service';
import { FlexForm } from './flex-form.class';
import { Constants } from '../constants.class';
import { FlexFormValidationService } from "./flex-form-validation.service";
import { PasswordWithConfirmation } from "./password-with-confirmation.class";
import { PasswordService } from '../password.service';
import { map } from "rxjs/operators";

@Injectable()
export class FlexFormService {

    flexFormSubject = new Subject<FlexForm>();

    constructor(
        private httpClient: HttpClient,
        private flexFormValidationService: FlexFormValidationService,
        private passwordService: PasswordService,
        private analyticsTrackingService: AnalyticsTrackingService
    ) { }

    getFlexForm(url: string, languageCode?: string, eligibilityToken?: string, ssoRedirectPath?: string, redirectedFrom?: string): Observable<FlexForm> {
        let httpParams = new HttpParams();

        if (languageCode) {
            httpParams = httpParams.set('language', languageCode);
        }

        if (eligibilityToken) {
            httpParams = httpParams.set('enrollmentToken', eligibilityToken);
        }

        if (ssoRedirectPath) {
            httpParams = httpParams.set('ssoRedirectPath', ssoRedirectPath);
        }

        if (redirectedFrom) {
            httpParams = httpParams.set('redirectedFrom', redirectedFrom);
        }

        return this.parseFlexForm(
            this.httpClient.get<FlexForm>(url, { params: httpParams })
        );
    }

    createFormGroup(flexForm: FlexForm) {
        if (flexForm.pageType === Constants.PAGE_TYPE.SPONSOR_SEARCH) {
            return new UntypedFormGroup({ searchString: new UntypedFormControl() })
        }

        const formGroupFields: any = {};

        flexForm.fields.forEach((field: FlexFormField) => {
            field.identityApiProvider = flexForm.attributes.identityProviderType
            formGroupFields[field.fieldName] = this.getFormControl(field);
        });

        let validators = [];

        if (flexForm.pageType == Constants.PAGE_TYPE.PRODUCT_SELECT) {
            validators.push(
                this.flexFormValidationService.productSelectValidator(
                    flexForm.fields.map(field => field.fieldName)
                )
            );
        }

        return new UntypedFormGroup(formGroupFields, validators);
    }

    getFormControl(field: FlexFormField): UntypedFormControl {
        let formValue = field.initialValue;
        if (formValue == "true") {
            formValue = true;
        }

        if (formValue == "false") {
            formValue = false;
        }

        return new UntypedFormControl(
            {
                value: formValue,
                disabled: (field.editable === false)
            },
            this.getFormFieldValidators(field)
        );
    }

    getFormFieldValidators(field: FlexFormField): Array<ValidatorFn> {
        const validators: ValidatorFn[] = [];

        if (field.required) {
            validators.push(Validators.required);
        }

        switch (field.componentType) {
            case Constants.COMPONENT_TYPE.PASSWORD:
                validators.push(
                    this.flexFormValidationService.passwordLowercaseLetterValidator(),
                    this.flexFormValidationService.passwordUppercaseLetterValidator(),
                    this.flexFormValidationService.passwordNumberValidator(),
                    this.flexFormValidationService.passwordSymbolValidator(),
                    this.flexFormValidationService.confirmationValidator(),
                    this.flexFormValidationService.passwordLength()
                );
                break;

            case Constants.COMPONENT_TYPE.DATE_OF_BIRTH:
                validators.push(
                    this.flexFormValidationService.dateFormat(),
                    this.flexFormValidationService.minAge(),
                    this.flexFormValidationService.maxAge()
                );
                break;

            case Constants.COMPONENT_TYPE.BIRTH_YEAR:
                validators.push(
                    Validators.min(Constants.MINIMUM_BIRTH_YEAR),
                    Validators.max(new Date().getFullYear() - Constants.MINIMUM_AGE)
                );
                break;

            case Constants.COMPONENT_TYPE.USERNAME:
                validators.push(
                    this.flexFormValidationService.usernameValidator()
                );
                break;

            case Constants.COMPONENT_TYPE.SECURITY_QUESTIONS:
                validators.push(
                    this.flexFormValidationService.securityQuestionValidator()
                );
                break;

            default:
                break;
        }

        return validators.length === 0 ? null : validators;
    }

    submitFlexForm(formValues: { [key: string]: any }, flexForm: FlexForm, languageCode?: string, submitUrl?: string): Observable<FlexForm> {
        let data: any = this.getRequestObject(formValues, flexForm);
        let options = {};

        if (languageCode) {
            options = {
                params: new HttpParams().set('language', languageCode)
            };
        }

        return this.parseFlexForm(
            this.httpClient.post<FlexForm>(
                this.getFullUrl(submitUrl || flexForm?.submitUrl),
                data,
                options
            )
        );
    }

    submitOtherForm(data: any, submitUrl: string, languageCode?: string) {
        let options = {};
        if (languageCode) {
            options = {
                params: new HttpParams().set('language', languageCode)
            };
        }

        this.parseFlexForm(
            this.httpClient.post<FlexForm>(this.getFullUrl(submitUrl), data, options)
        ).subscribe((newFlexForm: FlexForm) => {
            this.flexFormSubject.next(newFlexForm);
        }, (err: any) => {
            this.flexFormSubject.error(err);
        });
    }

    getRequestObject(formValues: { [key: string]: any }, flexForm: FlexForm): any {
        let request = {};

        let agreementFields = flexForm.fields.filter(
            field => field.componentType === Constants.COMPONENT_TYPE.AGREEMENT_CHECKBOX
        );

        if (agreementFields?.length) {
            request[Constants.FIELD_NAME.AGREEMENTS] = agreementFields.map(
                field => formValues[field?.fieldName]
            )
        }

        flexForm.fields.forEach((field) => {
            if (field.componentType !== Constants.COMPONENT_TYPE.AGREEMENT_CHECKBOX) {
                let rawValue = formValues[field?.fieldName];
                let finalValue: any = null;

                switch (field.componentType) {
                    case Constants.COMPONENT_TYPE.PASSWORD:
                        finalValue = (rawValue as PasswordWithConfirmation)?.password;
                        this.passwordService.setPassword(finalValue)
                        break;

                    case Constants.COMPONENT_TYPE.STATE_OF_RESIDENCE:
                        finalValue = rawValue == Constants.PLACEHOLDER_STATE_ID ? null : rawValue;
                        break;

                    default:
                        finalValue = rawValue;
                        break;
                }

                request[field.fieldName] = finalValue;
            }
        });

        if (flexForm.progressData) {
            request['progressData'] = flexForm.progressData;
        }

        return request;
    }

    getFullUrl(submitUrl: string): string {
        let urlBase = '/enrollment-api';

        if (!submitUrl) {
            return null;
        }

        if (submitUrl.startsWith('/')) {
            return urlBase.concat(submitUrl);
        } else {
            return urlBase.concat('/').concat(submitUrl);
        }
    }

    sendAnalyticsData(analyticsData: AnalyticsData) {
        if (!analyticsData) return;

        const { ['event_name']: eventName, ...trackingData } = analyticsData;

        this.analyticsTrackingService.sendData(eventName, trackingData, false);
    }

    /*
        Convert the API response to a true FlexForm object.
        HttpClient asserts the response type but doesn't cast the object, so
        we have to manually call a constructor.
    */
    parseFlexForm(flexFormResponse: Observable<FlexForm>): Observable<FlexForm> {
        return flexFormResponse.pipe<FlexForm>(
            map(flexForm => FlexForm.fromFlexForm(flexForm))
        );
    }
}
