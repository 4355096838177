import { StateService, Transition, UIRouter } from '@uirouter/angular';
import { LanguagesService } from './shared/languages.service';

import { TranslateService } from '@ngx-translate/core';

import * as _ from 'lodash';

import { SponsorSettingsService } from './shared/sponsor-settings.service';
import { EnrollmentGroup } from './shared/enrollment-group.class';
import { SponsorSettings } from './shared/sponsor-settings.class';
import { FlexFormPageComponent } from './flex-forms/flex-form-page/flex-form-page.component';
import { FlexFormEnrollmentGroupsService } from './shared/flex-forms/flex-form-enrollment-groups.service';
import { GenesisApiEnrollmentSponsorsService } from './shared/genesis-api/services/genesis-api-enrollment-sponsors.service';
import { GenesisSponsor } from './shared/genesis-sponsor.class';
import { SponsorEnrollmentGroupsService } from './shared/sponsor-enrollment-groups.service';

export const states = [
    {
        name: 'home',
        url: '/?language',
        component: FlexFormPageComponent,
        resolve: [
            {
                token: 'setLanguage',
                deps: [Transition, TranslateService, LanguagesService],
                resolveFn: setLanguage
            }
        ]
    },
    {
        name: 'enrollmentGroupSelection',
        url: '/sponsors/:sponsorId/enrollmentGroups?language',
        resolve: [
            {
                token: 'sponsorSettings',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getSponsorSettingsBySponsorId
            },
            {
                token: 'genesisSponsor',
                deps: [ Transition, GenesisApiEnrollmentSponsorsService ],
                resolveFn: getGenesisSponsor
            },
            {
                token: 'redirectToFlexEnrollmentGroupSelection',
                deps: [ Transition, StateService, 'sponsorSettings', 'genesisSponsor' ],
                resolveFn: redirectToFlexEnrollmentGroupSelection
            }
        ]
    },
    {
        name: 'selectedSponsorRedirect',
        url: '/sponsors/:sponsorId?language',
        redirectTo: 'enrollmentGroupSelection'
    },
    {
        name: 'enrollmentGroupRedirect',
        url: '/sponsors/:sponsorId/',
        redirectTo: 'enrollmentGroupSelection'
    },
    {
        name: 'validate',
        url: '/sponsors/:sponsorId/enrollmentGroups/:enrollmentGroupId?redirectFrom&eligibilityToken&postEnrollmentRedirectPath',
        resolve: [
            {
                token: 'sponsorSettings',
                deps: [Transition, SponsorSettingsService],
                resolveFn: getSponsorSettingsBySponsorId
            },
            {
                token: 'enrollmentGroup',
                deps: [SponsorEnrollmentGroupsService, Transition, TranslateService, 'sponsorSettings'],
                resolveFn: getEnrollmentGroup
            },
            {
                token: 'redirectFromV2ToFlex',
                deps: [Transition, StateService, 'enrollmentGroup'],
                resolveFn: redirectFromV2ToFlex
            },
        ]
    },
    {
        name: 'flex_no_step',
        url: '/enrollmentGroups/:enrollmentGroupExternalId?redirectFrom&emailVerification&eligibilityToken&postEnrollmentRedirectPath',
        resolve: [
            {
                token: 'redirect',
                deps: [Transition, StateService],
                resolveFn: redirectToFlex
            }
        ]
    },
    {
        name: 'flex',
        url: '/enrollmentGroups/:enrollmentGroupExternalId/step/:step?redirectFrom&emailVerification&eligibilityToken&postEnrollmentRedirectPath',
        component: FlexFormPageComponent
    },
    {
        name: 'flexEnrollmentGroupRedirect',
        url: '/sponsor/:sponsorExternalId?language',
        redirectTo: 'flexEnrollmentGroupSelection'
    },
    {
        name:'flexEnrollmentGroupSelection',
        url: '/sponsor/:sponsorExternalId/enrollment-groups?language',
        component: FlexFormPageComponent,
        resolve: [
            {
                token: 'setLanguage',
                deps: [Transition, TranslateService, LanguagesService],
                resolveFn: setLanguage
            },
            {
                token: 'enrollmentGroupsBySponsorGuid',
                deps: [ FlexFormEnrollmentGroupsService, Transition ],
                resolveFn: getEnrollmentGroupsListBySponsorGuid
            },
            {
                token: 'isSingleEnrollmentGroup',
                deps: [ Transition, StateService, 'enrollmentGroupsBySponsorGuid' ],
                resolveFn: isSingleEnrollmentGroup
            }
        ]
    }
];

export function routerSetup(router: UIRouter) {
    router.urlService.rules.otherwise({ state: 'home' });
}

export function getSponsorSettingsBySponsorId(transition: Transition, sponsorSettingsService: SponsorSettingsService) {
    return sponsorSettingsService.getSponsorSettingsById(transition.params().sponsorId).toPromise();
}

export function getEnrollmentGroup(
    sponsorEnrollmentGroupsService: SponsorEnrollmentGroupsService,
    transition: Transition,
    translateService: TranslateService,
    sponsorSettings: SponsorSettings
) {
    return sponsorEnrollmentGroupsService.loadEnrollmentGroup(sponsorSettings.sponsorId, transition.params().enrollmentGroupId, translateService.currentLang).toPromise();
}

export function setLanguage(
    transition: Transition,
    translateService: TranslateService,
    languagesService: LanguagesService
) {
    if (transition.params().language) {
        languagesService.isDefaultLanguageSet().subscribe((defaultLanguageSet) => {
            if (defaultLanguageSet) {
                translateService.use(transition.params().language);
            }
        })
    }
}

export function redirectToFlex(
    transition: Transition,
    state: StateService
) {
    transition.abort();
    state.go('flex', _.extend({step: 1}, transition.params()));
    return true;
}

export function redirectFromV2ToFlex(
    transition: Transition,
    state: StateService,
    enrollmentGroup: EnrollmentGroup
) {
    transition.abort();
    state.go('flex', _.extend({
        step: 1,
        enrollmentGroupExternalId: enrollmentGroup.externalId,
        emailVerification: transition.params().token
    }, transition.params()));
    return true;
}

export function getEnrollmentGroupsListBySponsorGuid(
    flexFormEnrollmentGroupsService: FlexFormEnrollmentGroupsService,
    transition: Transition
) {
    return flexFormEnrollmentGroupsService.getEnrollmentGroupsBySponsorGuid(transition.params().sponsorExternalId).toPromise();
}

export function isSingleEnrollmentGroup(
    transition: Transition,
    state: StateService,
    groups: EnrollmentGroup[]
) {
    if (groups.length === 1) {
        transition.abort();
        state.go('flex', _.extend({ enrollmentGroupExternalId: groups[0].externalId, step: 1 }, transition.params()), { location: 'replace' });
        return true;
    }
}

export function getSponsorSettingsBySponsorGuid(
    sponsorSettingsService: SponsorSettingsService,
    transition: Transition
) {
    return sponsorSettingsService.getSponsorSettingsByExternalId(transition.params().sponsorExternalId).toPromise();
}

export function getGenesisSponsor(
    transition: Transition,
    genesisEnrollmentSponsorService: GenesisApiEnrollmentSponsorsService
) {
    return genesisEnrollmentSponsorService.getSponsorInfoBySponsorId(transition.params().sponsorId).toPromise();
}

export function redirectToFlexEnrollmentGroupSelection(
    transition: Transition,
    state: StateService,
    sponsorSettings: SponsorSettings,
    genesisSponsor: GenesisSponsor
) {
    transition.abort();
    state.go('flexEnrollmentGroupSelection', _.extend({ sponsorExternalId: genesisSponsor.guid }, transition.params()), { location: 'replace' });
    return true;
}
