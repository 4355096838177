<div id="birthdate-warning"
     class="birthdate-warning"
     *ngIf="showWarning"
     role="alert">
  <small class="sr-only" [translate]="'enrollment.screenReader.warning'"></small>
  <vpg-inline-label [id]="'age-consent-message'"
                    [variant]="'attention'"
                    [text]="'enrollment.forms.errors.underAgeMustSubmitConsentForm' | translate:{ consentFormURL: consentFormURL, consentDeadline: deadline }">
  </vpg-inline-label>
</div>
