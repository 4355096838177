<div [formGroup]="form" class="flex-form-field" [ngClass]="{'display-none': isFieldHidden}">
    <div [ngSwitch]="field.componentType" class="flex-form-field-container">
        <reactive-textbox *ngSwitchCase="COMPONENT_TYPE.TEXTBOX" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" [isRequired]="field.required">
        </reactive-textbox>

        <reactive-country *ngSwitchCase="COMPONENT_TYPE.COUNTRY" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" [isRequired]="field.required">
        </reactive-country>

        <reactive-state-province *ngSwitchCase="COMPONENT_TYPE.STATE_PROVINCE" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" [enrollmentGroupExternalId]="enrollmentGroupExternalId" [isRequired]="field.required">
        </reactive-state-province>

        <reactive-state-of-residence *ngSwitchCase="COMPONENT_TYPE.STATE_OF_RESIDENCE" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" (isFieldHidden)="handleFieldHidden($event)" [isRequired]="field.required">
        </reactive-state-of-residence>

        <reactive-company *ngSwitchCase="COMPONENT_TYPE.COMPANY" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" (isFieldHidden)="handleFieldHidden($event)" [isRequired]="field.required">
        </reactive-company>

        <reactive-business-unit *ngSwitchCase="COMPONENT_TYPE.BUSINESS_UNIT" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" (isFieldHidden)="handleFieldHidden($event)" [isRequired]="field.required">
        </reactive-business-unit>

        <reactive-office *ngSwitchCase="COMPONENT_TYPE.OFFICE" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" (isFieldHidden)="handleFieldHidden($event)" [isRequired]="field.required">
        </reactive-office>

        <reactive-password *ngSwitchCase="COMPONENT_TYPE.PASSWORD" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" [form]="form" [isRequired]="field.required">
        </reactive-password>

        <reactive-email-address *ngSwitchCase="COMPONENT_TYPE.EMAIL_ADDRESS" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" [form]="form" [enrollmentGroupExternalId]="enrollmentGroupExternalId" [isRequired]="field.required">
        </reactive-email-address>

        <reactive-dropdown *ngSwitchCase="COMPONENT_TYPE.DROPDOWN" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" [isRequired]="field.required">
        </reactive-dropdown>

        <reactive-birth-year *ngSwitchCase="COMPONENT_TYPE.BIRTH_YEAR" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" [form]="form" [isRequired]="field.required">
        </reactive-birth-year>

        <reactive-date-of-birth *ngSwitchCase="COMPONENT_TYPE.DATE_OF_BIRTH" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" [form]="form" [isRequired]="field.required">
        </reactive-date-of-birth>

        <reactive-phone-number-groove *ngSwitchCase="COMPONENT_TYPE.PHONE_NUMBER" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" [form]="form" [isRequired]="field.required">
        </reactive-phone-number-groove>

        <reactive-username *ngSwitchCase="COMPONENT_TYPE.USERNAME" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" [form]="form" [isRequired]="field.required">
        </reactive-username>

        <reactive-confirmation-email-sent *ngSwitchCase="COMPONENT_TYPE.CONFIRMATION_EMAIL_SENT" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" [form]="form"
            [registrationDetails]="registrationDetails">
        </reactive-confirmation-email-sent>

        <reactive-agreement-checkbox *ngSwitchCase="COMPONENT_TYPE.AGREEMENT_CHECKBOX" [id]="field.fieldName + '-field'"
                                     [formControlName]="field.fieldName" [field]="field" [form]="form"
                                     [enrollmentGroupExternalId]="enrollmentGroupExternalId">
        </reactive-agreement-checkbox>

        <reactive-waitlist-confirmation *ngSwitchCase="COMPONENT_TYPE.WAITLIST_CONFIRMATION" [id]="field.fieldName + '-field'" [field]="field"></reactive-waitlist-confirmation>

        <reactive-security-questions *ngSwitchCase="COMPONENT_TYPE.SECURITY_QUESTIONS" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" [form]="form" [isRequired]="field.required">
        </reactive-security-questions>

        <reactive-enrollment-success-confirmation *ngSwitchCase="COMPONENT_TYPE.ENROLLMENT_SUCCESS_CONFIRMATION" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" [form]="form">
        </reactive-enrollment-success-confirmation>

        <reactive-app-download *ngSwitchCase="COMPONENT_TYPE.APP_DOWNLOAD" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" [form]="form">
        </reactive-app-download>

        <reactive-product-select *ngSwitchCase="COMPONENT_TYPE.PRODUCT_SELECT" [id]="field.fieldName + '-field'"
            [formControlName]="field.fieldName" [field]="field" [form]="form" [progressData]="progressData">
        </reactive-product-select>

        <reactive-checkbox *ngSwitchCase="COMPONENT_TYPE.CHECKBOX" [id]="field.fieldName + '-field'"
                           [formControlName]="field.fieldName" [field]="field" [form]="form">
        </reactive-checkbox>
    </div>
</div>
