<div>
    <vpg-segmented-progress-bar class="spb-main horizontal-progress-bar"
                                [orientation]="'vertical'"
                                [progress]="currentProgress"
                                [screenReaderText]="'enrollment.screenReader.youAreOnProgressBar' | translate">
        <vpg-segmented-progress-bar-segment *ngFor="let step of progressBarSteps; let i = index"
                                            [orientation]="'vertical'"
                                            [screenReaderText]="step.readerText">
            <vpg-segmented-progress-bar-step [points]="step.points" [completed]="currentProgress >= step.points">
                <vpg-icon-next [icon]="step.iconClass" size="lg"/>
            </vpg-segmented-progress-bar-step>
            <vpg-segmented-progress-bar-indicator *ngIf="i < progressBarSteps.length - 1"
                                                  [orientation]="'vertical'"
                                                  [progress]="currentProgress >= step.max ? 100 : 0"
                                                  class="spb-indicator"
                                                  [min]="step.min"
                                                  [max]="step.max"/>
            <vpg-segmented-progress-bar-title class="spb-title">
                {{ step.title }}
            </vpg-segmented-progress-bar-title>
            <vpg-segmented-progress-bar-description class="spb-description" *ngIf="step.description">
                {{ step.description }}
            </vpg-segmented-progress-bar-description>
        </vpg-segmented-progress-bar-segment>
    </vpg-segmented-progress-bar>
    <div class="mobile-progress-bar">
        <div class="mobile-progress-bar-text"
             [innerHtml]="'enrollment.flex.progress.step' | translate: { currentStep: progressBarCurrentStep + 1, totalSteps: progressBarSteps.length }">
        </div>
    </div>
</div>
