import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FeatureToggle } from './feature-toggle.enum';

@Injectable()
export class FeatureToggleService {

    constructor(
        private httpClient: HttpClient
    ) { }

    isFeatureToggleEnabled(featureToggleKey: FeatureToggle) {
        let url = `enrollment-api/feature-toggle/${featureToggleKey}`

        return this.httpClient.get<boolean>(url)
    }
}
