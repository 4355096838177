// Angular core
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// External
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TransitionService, UIRouterModule } from '@uirouter/angular';
import * as _ from 'lodash';
import * as moment from 'moment';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CookieModule, CookieService } from 'ngx-cookie';
import { VpgrooveModule } from '@engineering/genesis-frontend';
// Routing
import { routerSetup, states } from './routing.config';

// Services
import { environment } from '../environments/environment';
import { AnalyticsTrackingService } from './analytics-tracking/analytics-tracking.service';
import { CountryService } from './shared/country.service';
import { CurrentMemberService } from './shared/current-member.service';
import { GenesisApiEnrollmentSponsorsService } from './shared/genesis-api/services/genesis-api-enrollment-sponsors.service';
import { GenesisSponsorService } from './shared/genesis-sponsor.service';
import { LanguagesService } from './shared/languages.service';
import { PageNameService } from './shared/page-name.service';
import { SponsorCompaniesService } from './shared/sponsor-companies.service';
import { SponsorEnrollmentGroupsService } from './shared/sponsor-enrollment-groups.service';
import { SponsorSettingsService } from './shared/sponsor-settings.service';
import { SponsorValidationService } from './validation/sponsor-validation.service';
import { PasswordService } from './shared/password.service';
import { FeatureToggleService } from './shared/feature-toggle.service';

// Authentication
import { KeycloakLoginService } from './shared/authentication/keycloak-login.service';
import { AudioFingerprintService } from './shared/authentication/audioFingerprint.service';
import { EnrollmentFingerprintService } from './shared/authentication/fingerprint.service';

// Application
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { EnrollmentHttpInterceptor } from './shared/interceptors/enrollmentHttpInterceptor.class';
import { SwitchCasesDirective } from './utils/jr-switch-cases.directive';
import { IeWarningBannerComponent } from './ie-warning-banner/ie-warning-banner.component';

// Custom Form Controls
import { AgeConsentWarningComponent } from './utils/forms/age-consent-warning/age-consent-warning.component';
import { FlexFormFieldComponent } from './flex-forms/flex-form-field/flex-form-field.component';
import { FlexFormComponent } from './flex-forms/flex-form/flex-form.component';
import { FlexFormPageComponent } from './flex-forms/flex-form-page/flex-form-page.component';
import { FlexFormService } from './shared/flex-forms/flex-form.service';
import { BaseOrgHierarchyUnitComponent } from './utils/forms/reactive/base-org-hierarchy-unit/base-org-hierarchy-unit.component';
import { ReactiveBusinessUnitComponent } from './utils/forms/reactive/reactive-business-unit/reactive-business-unit.component';
import { ReactiveOfficeComponent } from './utils/forms/reactive/reactive-office/reactive-office.component';
import { ReactiveStateProvinceComponent } from './utils/forms/reactive/reactive-state-province/reactive-state-province.component';
import { ReactiveCheckboxComponent } from './utils/forms/reactive/reactive-checkbox/reactive-checkbox.component';
import { ReactiveCountryComponent } from './utils/forms/reactive/reactive-country/reactive-country.component';
import { ReactiveCompanyComponent } from './utils/forms/reactive/reactive-company/reactive-company.component';
import { ReactiveDropdownComponent } from './utils/forms/reactive/reactive-dropdown/reactive-dropdown.component';
import { ReactiveStateOfResidenceComponent } from './utils/forms/reactive/reactive-state-of-residence/reactive-state-of-residence.component';
import { ReactiveEmailAddressComponent } from './utils/forms/reactive/reactive-email-address/reactive-email-address.component';
import { ReactiveDateOfBirthComponent } from './utils/forms/reactive/reactive-date-of-birth/reactive-date-of-birth.component';
import { ReactiveBirthYearComponent } from './utils/forms/reactive/reactive-birth-year/reactive-birth-year.component';
import { ReactiveConfirmationEmailSentComponent } from './utils/forms/reactive/reactive-confirmation-email-sent/reactive-confirmation-email-sent.component';
import { ReactivePasswordComponent } from './utils/forms/reactive/reactive-password/reactive-password.component';
import { ReactiveTextboxComponent } from './utils/forms/reactive/reactive-textbox/reactive-textbox.component';
import { ReactiveUsernameComponent } from './utils/forms/reactive/reactive-username/reactive-username.component';
import { ReactiveAgreementCheckboxComponent } from './utils/forms/reactive/reactive-agreement-checkbox/reactive-agreement-checkbox.component';
import { ReactiveSecurityQuestionsComponent } from './utils/forms/reactive/reactive-security-questions/reactive-security-questions.component';
import { ReactiveEnrollmentSuccessConfirmationComponent } from './utils/forms/reactive/reactive-enrollment-success-confirmation/reactive-enrollment-success-confirmation.component';
import { FlexFormHeaderComponent } from './flex-forms/flex-form-header/flex-form-header.component';
import { LanguageComponent } from './utils/forms/language/language.component';
import { ProgressBarComponent } from './utils/forms/progress-bar/progress-bar.component';
import { ReactivePhoneNumberComponent } from './utils/forms/reactive/reactive-phone-number/reactive-phone-number-groove.component';
import { GenericGrooveWrapperComponent } from './utils/forms/groove/generic-groove-wrapper/generic-groove-wrapper.component';
import { GrooveInputFieldComponent } from './utils/forms/groove/groove-input-field/groove-input-field.component';
import { GroovePasswordFieldComponent } from './utils/forms/groove/groove-password-field/groove-password-field.component';
import { GroovePhoneNumberFieldComponent } from './utils/forms/groove/groove-phone-number-field/groove-phone-number-field.component';
import { GrooveSearchFieldComponent } from './utils/forms/groove/groove-search-field/groove-search-field.component';
import { GrooveCheckboxFieldComponent } from './utils/forms/groove/groove-checkbox-field/groove-checkbox-field.component';
import { ReactiveAppDownloadComponent } from './utils/forms/reactive/reactive-app-download/reactive-app-download.component';
import { GrooveDropdownFieldComponent } from './utils/forms/groove/groove-dropdown-field/groove-dropdown-field.component';
import { ReactiveWaitlistConfirmationComponent } from './utils/forms/reactive/reactive-waitlist-confirmation/reactive-waitlist-confirmation.component';
import { ReactiveProductSelectComponent } from './utils/forms/reactive/reactive-product-select/reactive-product-select.component';
import { ReactiveSponsorSearchComponent } from './utils/forms/reactive/reactive-sponsor-search/reactive-sponsor-search.component';
import { FlexFormEnrollmentGroupsService } from './shared/flex-forms/flex-form-enrollment-groups.service';
import { ReactiveSecurityQuestionComponent } from './utils/forms/reactive/reactive-security-questions/reactive-security-question/reactive-security-question.component';
import { AgreementModalComponent } from './utils/forms/reactive/reactive-agreement-checkbox/agreement-modal/agreement-modal.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initializeFingerprint(
    fingerprintService: EnrollmentFingerprintService
) {
    return () => fingerprintService.saveCustomFingerprint();
}

export function initializeStorageListener(
    fingerprintService: EnrollmentFingerprintService
) {
    return () => fingerprintService.initStorageListener();
}

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        SwitchCasesDirective,
        IeWarningBannerComponent,
        AgeConsentWarningComponent,
        FlexFormFieldComponent,
        FlexFormComponent,
        FlexFormPageComponent,
        ProgressBarComponent,
        BaseOrgHierarchyUnitComponent,
        ReactiveBusinessUnitComponent,
        ReactiveOfficeComponent,
        ReactiveStateProvinceComponent,
        ReactiveCheckboxComponent,
        ReactiveCountryComponent,
        ReactiveCompanyComponent,
        ReactiveDropdownComponent,
        ReactiveStateOfResidenceComponent,
        ReactiveEmailAddressComponent,
        ReactiveDateOfBirthComponent,
        ReactiveBirthYearComponent,
        ReactiveConfirmationEmailSentComponent,
        ReactivePasswordComponent,
        ReactiveTextboxComponent,
        ReactiveUsernameComponent,
        ReactiveAgreementCheckboxComponent,
        ReactiveSecurityQuestionsComponent,
        ReactiveEnrollmentSuccessConfirmationComponent,
        ReactiveWaitlistConfirmationComponent,
        FlexFormHeaderComponent,
        LanguageComponent,
        ReactivePhoneNumberComponent,
        GenericGrooveWrapperComponent,
        GrooveInputFieldComponent,
        GroovePasswordFieldComponent,
        GroovePhoneNumberFieldComponent,
        GrooveSearchFieldComponent,
        GrooveCheckboxFieldComponent,
        ReactiveAppDownloadComponent,
        GrooveDropdownFieldComponent,
        ReactiveProductSelectComponent,
        ReactiveSponsorSearchComponent,
        ReactiveSecurityQuestionComponent,
        AgreementModalComponent
    ],
    imports: [
        UIRouterModule.forRoot({
            states: states,
            useHash: true,
            config: routerSetup
        }),
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        CookieModule.forRoot(),
        ReactiveFormsModule,
        PopoverModule.forRoot(),
        TypeaheadModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        VpgrooveModule
    ],
    providers: [
        CookieService,
        CurrentMemberService,
        LanguagesService,
        SponsorSettingsService,
        SponsorValidationService,
        SponsorCompaniesService,
        GenesisSponsorService,
        SponsorEnrollmentGroupsService,
        GenesisApiEnrollmentSponsorsService,
        KeycloakLoginService,
        CountryService,
        AnalyticsTrackingService,
        AudioFingerprintService,
        EnrollmentFingerprintService,
        PageNameService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EnrollmentHttpInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeFingerprint,
            deps: [EnrollmentFingerprintService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeStorageListener,
            deps: [EnrollmentFingerprintService],
            multi: true
        },
        FlexFormService,
        PasswordService,
        FlexFormEnrollmentGroupsService,
        FeatureToggleService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    readonly languageCodePrefixLength = 2;
    constructor(
        translateService: TranslateService,
        languagesService: LanguagesService,
        currentMemberService: CurrentMemberService,
        countryService: CountryService,
        private transitionService: TransitionService,
        private sponsorSettingsService: SponsorSettingsService,
        private pageNameService: PageNameService
    ) {
        this.transitionService.onSuccess({}, (transition) => {
            this.pageNameService.setPageName(transition?.to()?.name || '');

            if (transition.params().sponsorId) {
                this.sponsorSettingsService.getRegistrationDetailsBySponsorId(transition.params().sponsorId).subscribe();
            } else if (transition.params().enrollmentGroupExternalId) {
                this.sponsorSettingsService.getRegistrationDetailsByEnrollmentGroupExternalId(
                    transition.params().enrollmentGroupExternalId
                ).subscribe();
            } else if (transition.params().sponsorExternalId) {
                this.sponsorSettingsService.getRegistrationDetailsBySponsorGuid(
                    transition.params().sponsorExternalId
                ).subscribe();
            }
        });

        translateService.setDefaultLang(environment.defaultLanguage);
        translateService.onLangChange.subscribe((event) => {
            currentMemberService.memberEnrollmentData.language = event.lang;
            moment.locale(event.lang);
        });
        countryService.setSelectedCountry(null);

        languagesService.getLanguages().subscribe((languages) => {
            let defaultLanguage: string = translateService.getBrowserCultureLang();

            if (defaultLanguage && _.some(languages, { 'code': defaultLanguage })) {
                translateService.use(defaultLanguage);
            } else if (defaultLanguage.length === this.languageCodePrefixLength) {
                let foundLanguage = _.find(languages, function (item) {
                    return _.startsWith(item.code, defaultLanguage);
                });

                if (foundLanguage) {
                    defaultLanguage = foundLanguage.code;
                    translateService.use(defaultLanguage);
                }
            }
            languagesService.setDefaultLanguageStatus(true)
        });

        AnalyticsTrackingService.init();
    }
}
