import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ReplaySubject } from 'rxjs';

import { Company } from './company.class';
import { BusinessUnit } from './business-unit.class';
import { Office } from './office.class';
import { Constants } from './constants.class';

@Injectable()
export class SponsorCompaniesService {
  private cachedEnrollmentGroupExternalId: string = null;
  private cachedEnrollmentGroupCompanies: Array<Company> = null;

  businessUnits: ReplaySubject<Array<BusinessUnit>> = new ReplaySubject(1);
  offices: ReplaySubject<Array<Office>> = new ReplaySubject(1);

  constructor(private http: HttpClient) { }

  async getSponsorCompaniesByEnrollmentGroup(enrollmentGroupExternalId: string): Promise<Array<Company>> {
    let url = `/enrollment-api/enrollment-groups/${enrollmentGroupExternalId}/companies`;

    if (enrollmentGroupExternalId === this.cachedEnrollmentGroupExternalId) {
      return this.cachedEnrollmentGroupCompanies;
    }

    let companies = await this.http.get<Company[]>(url).toPromise();
    this.cachedEnrollmentGroupExternalId = enrollmentGroupExternalId;
    this.cachedEnrollmentGroupCompanies = companies;
    return companies;
  }

  updateOrgHierarchy(company: Company) {
    this.businessUnits.next(company?.businessUnits);
    this.offices.next(company?.offices);
  }

  getSubjectByType(type: string): ReplaySubject<Array<any>> {
    switch (type) {
      case Constants.COMPONENT_TYPE.BUSINESS_UNIT:
        return this.businessUnits;

      case Constants.COMPONENT_TYPE.OFFICE:
        return this.offices;

      default:
        return null;
    }
  }
}
