import { Component, Input } from '@angular/core';
import { ProgressBarStepData } from 'src/app/shared/flex-forms/progress-bar-step-data.class';
import { TranslateService } from '@ngx-translate/core';
import { GrooveProgressBarStepData } from 'src/app/shared/flex-forms/groove-progress-bar-step-data.class';

@Component({
    selector: 'progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
    progressBarSteps: GrooveProgressBarStepData[] = [];
    stepData: ProgressBarStepData[] = [];
    progressBarCurrentStep: number = null;
    currentProgress: number = 0;

    constructor(
        private translateService: TranslateService
    ) {
    }

    @Input() set steps(data: ProgressBarStepData[]) {
        if (data) {
            this.stepData = data;
            this.applyStepDataToModel(data);
        }
    }

    applyStepDataToModel(stepData: ProgressBarStepData[]) {
        const stepSize = 100 / stepData.length;
        this.progressBarCurrentStep = stepData?.findIndex((step: any) => step.isCurrentStep);
        this.currentProgress = (this.progressBarCurrentStep + 1) * stepSize;
        this.progressBarSteps = stepData?.map((step: any, index: number) => ({
            title: step.title,
            description: step.isCurrentStep ? step.description : '',
            points: stepSize + index * stepSize,
            min: stepSize + index * stepSize,
            max: stepSize + (index + 1) * stepSize,
            iconClass: index < this.progressBarCurrentStep ? 'fa fa-check' : `fa ${step.iconSource}`,
            readerText: step.isCurrentStep ? this.translateService.instant('enrollment.screenReader.stepCount', { stepNumber: (this.progressBarCurrentStep + 1), stepCount: stepData.length }) : ''
        }));
    }
}
