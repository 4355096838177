import { Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs';

import { Country } from './country.class';

@Injectable()
export class CountryService {
    private selectedCountry: ReplaySubject<Country> = new ReplaySubject<Country>(1);

    constructor() { }

    setSelectedCountry(country){
      this.selectedCountry.next(country);
    }

    getSelectedCountry(){
      return this.selectedCountry;
    }
}
